// /////////////////////////////////////////////////////////////////
// Copyright (C) 2018 by Hitachi, Ltd. All rights reserved.
// /////////////////////////////////////////////////////////////////
export default {
  default: {
    selectiveConditionsStrage: 'dsasKeywordDefinitionSelective',
    commonExcludeConditionsStrage: 'dsasKeywordDefinitionExclude',
    selectiveAuthorConditionsStrage: 'dsasAuthorDefinitionSelective',
    selectiveSpeakerConditionsStrage: 'dsasSpeakerDefinitionSelective',
    sysomos: {
      // eslint-disable-next-line prettier/prettier
      columns: [
        {
          headers: [
            'No',
            'Source',
            'Host',
            'Link',
            'Date(ET)',
            'Time(ET)',
            'LocalTime',
            'Category',
            'Author ID',
            'Author Name',
            'Author URL',
            'Authority',
            'Followers',
            'Following',
            'Age',
            'Gender',
            'Language',
            'Country',
            'Province/State',
            'City',
            'Location',
            'Sentiment',
            'Themes',
            'Classifications',
            'Entities',
            'Alexa Rank',
            'Alexa Reach',
            'Title',
            'Snippet',
            'Contents',
            'Summary',
            'Bio',
            'Unique ID',
            'Post Source'
          ],
          // チェック対象の列ヘッダ名。0番目から順に [ソース, URL, 日時, フォロワー数] 空文字はチェック対象にならない。
          checkColumns: ['Source', 'Link', 'LocalTime', '']
        }
      ],
      ext: ['csv'],
      headerRowIndex: -1,
      encode: 'utf-8',
      delimiter: ','
    },
    crimson: {
      // eslint-disable-next-line prettier/prettier
      columns: [
        {
          headers: [
            'GUID',
            'Date (JST)',
            'URL',
            'Contents',
            'Author',
            'Name',
            'Country',
            'State/Region',
            'City/Urban Area',
            'Category',
            'Emotion',
            'Source',
            'Gender',
            'Posts',
            'Followers',
            'Following',
            'Influence Score',
            'Post Title',
            'Post Type',
            'Image URL',
            'Brand'
          ],
          checkColumns: ['Source', 'URL', 'Date (JST)', '']
        },
        {
          headers: [
            'GUID',
            '日付(JST)',
            'URL',
            'コンテンツ',
            'インフルエンサー',
            '名前',
            '国',
            '州/地域',
            '市/都市圏',
            'カテゴリ',
            'Emotion',
            'ソース',
            '性別',
            '投稿',
            'フォロワー',
            'フォローする',
            'Influence Score',
            'Post Title',
            'Post Type',
            'Image URL',
            'Brand'
          ],
          checkColumns: ['ソース', 'URL', '日付(JST)', '']
        }
      ],
      ext: ['xls', 'xlsx'],
      headerRowIndex: 1,
      sheetIndex: 0
    },
    meltwater: {
      // eslint-disable-next-line prettier/prettier
      columns: [
        {
          headers: [
            'Date',
            'Headline',
            'URL',
            'Opening Text',
            'Hit Sentence',
            'Source',
            'Influencer'
          ],
          checkColumns: ['Source', 'URL', 'Date', '']
        }
      ],
      ext: ['csv'],
      headerRowIndex: 0,
      encode: 'utf-8',
      delimiter: '\t'
    },
    talkwalker: {
      // eslint-disable-next-line prettier/prettier
      columns: [
        {
          headers: [
            'url',
            'published',//date
            'content',
            'extra_author_attributes.short_name',
            'reach'
          ],
          checkColumns: ['url', 'published', '']
        }
      ],
      ext: ['csv', 'xlsx'],
      headerRowIndex: 0,
      encode: 'utf-8',
      delimiter: ',',
      sheetIndex: 0
    },
    netBase: {
      // eslint-disable-next-line prettier/prettier
      columns: [
        {
          headers: [
            '投稿 ID',
            'サウンド バイトのテキスト',
            '情報源の種類',
            '投稿タイプ',
            'URL',
            '公開日 (GMT+09:00) Tokyo',
            '投稿者の URL',
            '投稿者名',
            '投稿者の ID',
            'フォロワー数/日次ユニーク ビジター数/サブスクライバー数'
          ],
          checkColumns: ['情報源の種類', 'URL', '公開日 (GMT+09:00) Tokyo', '']
        }
      ],
      ext: ['xls', 'xlsx'],
      headerRowIndex: 0,
      sheetIndex: 0
    },
    sprinklr: {
      // eslint-disable-next-line prettier/prettier
      columns: [
        {
          headers: [
            'UniversalMessageId',
            'SocialNetwork',
            'SenderScreenName',
            'SenderListedName',
            'Sender Followers Count',
            'Message',
            'CreatedTime',
            'Language',
            'Permalink',
            'Message Type'
          ],
          checkColumns: [
            'SocialNetwork',
            'Permalink',
            'CreatedTime',
            'Sender Followers Count'
          ]
        }
      ],
      ext: ['xls', 'xlsx'],
      headerRowIndex: 0,
      sheetIndex: 0
    },
    kuchikomi: {
      // eslint-disable-next-line prettier/prettier
      columns: [
        {
          headers: [
            '日時',
            '曜日',
            '記事URL',
            '本文',
            '媒体',
            'サイト',
            '画像URL',
            'ユーザー名'
          ],
          checkColumns: ['サイト', '記事URL', '日時', '']
        }
      ],
      ext: ['xls', 'xlsx'],
      headerRowIndex: 0,
      sheetIndex: 1
    },
    textCsv: {
      columns: [
        {
          headers: ['date', 'contents', 'uid'],
          checkColumns: ['', '', 'date', '']
        }
      ],
      ext: ['csv'],
      headerRowIndex: 0,
      encode: 'utf-8',
      delimiter: ','
    },
    textExcel: {
      columns: [
        {
          headers: ['date', 'contents', 'uid'],
          checkColumns: ['', '', 'date', '']
        }
      ],
      ext: ['xls', 'xlsx'],
      headerRowIndex: 0,
      sheetIndex: 0
    },
    external: {
      // eslint-disable-next-line prettier/prettier
      columnsLength: 101,
      ext: ['xls', 'xlsx', 'csv'],
      headerRowIndex: 0,
      sheetIndex: 0
    },
    authCommon: {
      withinSeconds: 300,
      encryptAlgorithm: 'aes-256-cbc',
      sessionProp: 'DAUTH_TOKEN',
      timeCheckFlg: 'IS_CHECKED_TIME'
    }
  },
  development: {
    api: {
      endpoint: ''
    },
    auth: {
      dAuthHost: 'd-autht.dentsu.co.jp',
      aplId: 'S0138901',
      redirectUrl: 'https://dev.mind-look.com/auth/v0/transfer'
    },
    trackingId: 'UA-144625670-1'
  },
  stage: {
    api: {
      endpoint: ''
    },
    auth: {
      dAuthHost: 'd-auth.dentsu.co.jp',
      aplId: 'S0138901',
      redirectUrl: 'https://stage.mind-look.com/auth/v0/transfer'
    },
    trackingId: 'UA-144625670-1'
  },
  local: {
    api: {
      endpoint: 'http://localhost:3000/intra'
    },
    auth: {
      dAuthHost: 'd-autht.dentsu.co.jp',
      aplId: 'S0138901',
      redirectUrl: 'http://localhost:4000/intra/auth/v0/transfer'
    },
    trackingId: 'UA-144625670-1'
  },
  intra: {
    api: {
      endpoint: 'http://localhost:3000/intra'
    },
    auth: {
      dAuthHost: 'd-autht.dentsu.co.jp',
      aplId: 'S0138901',
      redirectUrl: 'http://localhost:4000/intra/auth/v0/transfer'
    },
    trackingId: 'UA-144625670-1'
  },
  production: {
    api: {
      endpoint: ''
    },
    auth: {
      dAuthHost: 'd-auth.dentsu.co.jp',
      aplId: 'S0138901',
      redirectUrl: 'https://mind-look.com/auth/v0/transfer'
    },
    trackingId: 'UA-147167790-1'
  }
}
